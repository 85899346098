import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { useSWRConfig } from 'swr'
import useSWRImmutable from 'swr/immutable'

import useJobListingQuery from '@features/jobs/hooks/useJobListingQuery'
import { useJobListingStateQueries } from '@features/jobs/job-detail/reducers/selectors'
import { useKycActions } from '@features/kyc/reducers/KycReducer'
import { GetEmployerProfileAPIResponseData } from '@features/kyc/types'

import { KYC_STATUS } from '@constants/kyc-status'
import { EMPLOYERS_PROFILE_URL, INITIALIZE_URL } from '@constants/url'

import PreKycVerificationDataModal from '../PreKycVerificationDataModal'

const KycVerificationBanner = () => {
  const route = useRouter()
  const kycActions = useKycActions()
  const { data: employerProfileData, mutate: mutateEmployerProfileData } =
    useSWRImmutable<GetEmployerProfileAPIResponseData>(EMPLOYERS_PROFILE_URL)
  const swrConfig = useSWRConfig()

  const jobListingQueries = useJobListingStateQueries()

  const companyId = employerProfileData?.company?.id || ''

  const jobListingQuery = useJobListingQuery({
    companyId,
    query: jobListingQueries,
  })

  const checkBanner = () => {
    if (
      employerProfileData?.kycStatus === KYC_STATUS.REJECTED &&
      employerProfileData?.company?.kycStatus === KYC_STATUS.REJECTED
    ) {
      return {
        icon: '/assets/icons/ic_verification_red.svg',
        text: 'Verifikasi Anda ditolak',
        subtext: 'Periksa alasan penolakan',
      }
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.REJECTED ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.REJECTED
    ) {
      return {
        icon: '/assets/icons/ic_verification_yellow.svg',
        text: 'Verifikasi Anda ditolak',
        subtext: 'Salah satu data anda tidak valid',
      }
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.IN_REVIEW ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.IN_REVIEW
    ) {
      return {
        icon: '/assets/icons/ic_verification_blue.svg',
        text: 'Verifikasi sedang berjalan',
        subtext: 'Proses maksimal 1x24 jam.',
      }
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.PENDING ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.PENDING
    ) {
      return {
        icon: '/assets/icons/ic_verification_grey.svg',
        text: 'Verifikasi kurang dari 5 menit!',
        subtext: 'Hadiah 50 Atma Coins senilai Rp 50.000',
      }
    }

    return {
      icon: '',
      text: '',
      subtext: '',
    }
  }

  const onClick = async () => {
    if (!employerProfileData || !employerProfileData.company) return
    const resp = await mutateEmployerProfileData()
    if (
      resp?.kycStatus === 'Approved' &&
      resp.company?.kycStatus === 'Approved' &&
      companyId
    ) {
      await Promise.all([
        jobListingQuery.mutate(),
        swrConfig.mutate(INITIALIZE_URL),
      ])
    }

    if (
      employerProfileData?.kycStatus === KYC_STATUS.PENDING ||
      employerProfileData?.company?.kycStatus === KYC_STATUS.PENDING
    ) {
      route.push({ pathname: '/kyc', query: { step: 'pre-kyc' } })
    } else {
      kycActions.togglePreKycVerificationModal()
    }
  }

  if (!employerProfileData) return null

  if (
    employerProfileData?.kycStatus === 'Approved' &&
    employerProfileData?.company?.kycStatus === 'Approved'
  )
    return null

  return (
    <>
      <button
        className="mb-4 flex w-full items-center justify-between rounded-md border bg-white px-[12px] py-[16px]"
        onClick={onClick}
        type="button"
      >
        <div className="flex items-center">
          <div className="relative mr-2 h-8 w-8">
            <Image src={checkBanner().icon} alt="icon" fill sizes="100vw" />
          </div>
          <div>
            <div className="flex items-center">
              <p className="text-start font-semibold">{checkBanner().text}</p>
            </div>
            <p className="text-xs text-start">{checkBanner().subtext}</p>
          </div>
        </div>
        <div className="relative h-8 w-8">
          <Image
            src="/assets/icons/ic_chevron_right_black.svg"
            alt="icon"
            fill
            sizes="100vw"
          />
        </div>
      </button>
      <PreKycVerificationDataModal />
    </>
  )
}

export default KycVerificationBanner
